<template>
  <Layout>
    <BHeader>
      <div
        v-loading="listInfo.loading"
        class="overview"
      >
        <div class="items">
          <div class="num">
            {{ listInfo.yesterdayBind }}
          </div>
          <div class="name">
            昨日新增绑定用户
          </div>
        </div>
        <div class="items">
          <div class="num">
            {{ listInfo.totalBind }}
          </div>
          <div class="name">
            已绑定用户
          </div>
        </div>
      </div>

      <div slot="right">
        <el-button
          class="download-icon"
          type="text"
          :loading="downloadLoading"
          @click="downloadGameStatics"
        >
          <i
            title="下载"
            class="fa fa-cloud-download icondown"
          />
        </el-button>
      </div>
    </BHeader>

    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      style="width: 100%"
      border
      :default-sort="{prop: formData.sortName, order: formData.sortType === 'desc' ? 'descending' : 'ascending'}"
      @sort-change="handleSortTable"
    >
      <el-table-column
        label="序号"
        type="index"
        :index="formData.pageSize * (formData.pageNum - 1) + 1"
        min-width="120px"
      />
      <el-table-column
        label="游戏ID"
        prop="steamGameId"
        min-width="180px"
      />
      <el-table-column
        label="游戏名"
        prop="steamGameName"
        min-width="120px"
      />
      <el-table-column
        label="拥有用户数量"
        prop="bindNum"
        sortable="custom"
        min-width="120px"
      />
      <el-table-column
        label="占比"
        prop="bindRatio"
        min-width="120px"
      />
    </el-table>

    <Pagination
      v-show="!listInfo.loading"
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
  </Layout>
</template>

<script>

import {
  getBindStremGameStatics,
  downloadBindStremGameStatics
} from '@/api/finance.js'
import { cloneDeep } from 'lodash'
export default {
  name: 'Library',
  data () {
    return {
      formData: {
        sortName: 'bindNum',
        sortType: 'desc',
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        yesterdayBind: '-',
        totalBind: '-',
        total: 0,
        loading: false
      },
      downloadLoading: false
    }
  },
  created () {
    this.queryDataList()
  },
  methods: {
    handleSortTable (row) {
      let { prop, order } = row
      if (order === null) {
        order = this.formData.sortType === 'asc' ? 'descending' : 'ascending'
        row.column.order = order
      }
      this.formData.sortName = prop
      const sortType = order === 'ascending' ? 'asc' : order === 'descending' ? 'desc' : ''
      this.formData.sortType = sortType
      this.queryDataList()
    },
    downloadGameStatics () {
      this.downloadLoading = true
      const query = cloneDeep(this.formData)
      delete query.pageNum
      delete query.pageSize
      downloadBindStremGameStatics(query)
        .then(res => {
          if (res.code === 200) {
            if (res?.data?.url) {
              this.downloadFile(res?.data?.url)
            } else {
              this.$message.error('下载失败, 请重试')
            }
          }
        })
        .finally(() => {
          this.downloadLoading = false
        })
    },
    downloadFile (url) {
      var a = document.createElement('a')
      a.href = url
      a.click()
      window.URL.revokeObjectURL(url)
    },
    queryDataList () {
      this.listInfo.loading = true
      const query = this.formData
      getBindStremGameStatics(query)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data?.list || []
            this.listInfo.total = res.data?.total || 0
            this.listInfo.yesterdayBind = res.data?.yesterdayBind ?? '-'
            this.listInfo.totalBind = res.data?.totalBind ?? '-'
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
  .overview {
    display: flex;
    // padding: 0 0 20px 0;
    min-width: 880px;
    margin-bottom: 10px;
    .items {
      // width: 210px;
      padding: 0 30px;
      height: 100px;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
      border-radius: 4px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .num {
        font-size: 26px;
        margin-bottom: 10px;
        .unit {
          font-size: 14px;
        }
      }
      .name {
        font-size: 12px;
      }
    }

    }
    .pre-header {
      height: 46px;
      .select-all {
        position: absolute;
        top: 0;
        z-index: 100;
        width: 100%;
        font-size: 14px;
        margin: 10px 0;
        padding: 10px 20px;
        cursor: pointer;
        background: #F5F7FA;
        box-sizing: border-box;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, .12);
        &::before {
          content: '';
          display: block;
          height: 10px;
          background: white;
          position: absolute;
          top: -10px;
          left: 0;
          width: 100%;
        }
        &:hover {
          color: cornflowerblue;
        }
      }
    }

    .download-icon {
      display: flex;
      align-items: center;
      .icondown {
        font-size: 35px;
      }
    }
</style>
